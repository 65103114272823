<template>
  <svg
    class="add-icon"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect v-if="isBorder" width="36" height="36" rx="18" fill="#EFF2F8" />
    <rect x="10.5" y="10.5" width="15" height="15" rx="7.5" stroke="#2F5582" />
    <rect x="14" y="17" width="8" height="2" rx="1" fill="#2F5582" />
    <rect
      x="19"
      y="14"
      width="8"
      height="2"
      rx="1"
      transform="rotate(90 19 14)"
      fill="#2F5582"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    isBorder: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.add-icon
  cursor pointer
</style>
